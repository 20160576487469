<template>
    <main class="main">
      <section class="section dashboard">
        <div>
          <div>
            <iframe :src="iframeUrl" width="100%" height="800" frameborder="0"></iframe>
          </div>
        </div>
      </section>
    </main>
  </template>
  <script>
    import { mapActions, mapGetters } from 'vuex'
    import jwt from 'jsonwebtoken'
    
    export default {
        name: 'index',
        data: () => ({
        users: [
            { name: 'João', id: 1 },
            { name: 'Maria', id: 2 }
        ],
        user: '',
        year: '',
        years: [2017, 2018, 2019, 2020],
        loading: false,
        METABASE_SITE_URL: process.env.VUE_APP_METABASE_URL,
        METABASE_SECRET_KEY: process.env.VUE_APP_METABASE_INDEX_SECRET_KEY,
        iframeUrl: ''
        }),
        methods: {
        ...mapActions('monetary', ['getData']),
        ...mapActions('monetary', ['getGraphics']),
        generateIframeUrl () {
            const user = JSON.parse(localStorage.getItem('user'))
            const payload = {
            resource: { dashboard: 10 },
            params: {
                //id_do_coletor_admin: user.id,
                //id_do_usuario: user.id,
                //id_da_empresa_coletora: user.company[0].company_id
            },
            exp: Math.round(Date.now() / 1000) + 600 // Expiração de 10 minutos
            }
            console.log('params', payload)
            const token = jwt.sign(payload, this.METABASE_SECRET_KEY)
            this.iframeUrl = this.METABASE_SITE_URL + '/embed/dashboard/' + token + '#bordered=false&titled=false'
        }
        },
        computed: {
        ...mapGetters('monetary', [
            'totalBalance',
            'cashBalance',
            'balanceCollectors',
            'graphicDatas'
        ])
        },
        created () {
        // this.loading = true
        // await this.getData()
        // await this.getGraphics()
        // this.loading = false
        this.generateIframeUrl()
        }
    }
  </script>
  
  <style scoped>
  .graphics {
    display: flex;
    border: none;
    margin-bottom: 15px;
    width: 100%;
  }
  
  .first {
    margin-right: 18px;
  }
  
  .card h3 {
    text-align: left;
  }
  
  .card_home {
    box-shadow: 0 0 30px 0 rgba(47, 47, 47, 0.1);
    background-color: #fff;
    border-radius: 10px;
    transition: all 0.3s ease-in-out;
    border: 2px solid #fff;
    margin-bottom: 15px;
    text-align: center;
  }
  
  .card_total,
  .card_home {
    min-height: 7.4rem;
    padding: 20px 10px 20px 10px;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  .card_collections {
    box-shadow: 0 0 30px 0 rgba(47, 47, 47, 0.1);
    background-color: #fff;
    border-radius: 10px;
    transition: all 0.3s ease-in-out;
    border: 2px solid #fff;
    padding: 0px 20px;
    margin: 0 auto 2rem auto;
  }
  .dropdown {
    border: 1.5px solid #d1d5dd;
    padding: 5px;
    border-radius: 5px;
    margin: 5px;
  }
  .main {
    padding: 30px 0 70px 0;
    min-height: calc(100vh - 105px);
  }
  </style>